<template>
	<div class="container">
		<div class="paper">
			<img class="w-100" :src="require('../../assets/img/icon/banner.png')" alt="">
			<div class="row mt-5" style="margin:0;">
				<div class="col-md-6 col-sm-12 col-12 profile-dao-bar mb-5 ls-flex-ac">
					<div>My W3C</div>
				</div>
				<div class="col-sm-10">
					W3C Balance : {{coinBalanceFm}}
				</div>
				<div class="col-sm-2 ls-flex-ac">
					<div class="el-btn ls-btn ls-flex-ajc ls-flex-1" @click="airdropClaim()">claim</div>
				</div>
			</div>
			
			<div class="row mt-5" style="margin:0;">
				<div class="col-md-6 col-sm-12 col-12 profile-dao-bar mb-5 ls-flex-ac">
					{{nftList.length>0?'My NFT':store.state.lang.myDaos}}
					<!-- <div>My Daos</div> -->
				</div>
				<div v-if="nftList.length" class="col-md-6 col-sm-12 col-12 ls-flex-ajc mb-5">
					<div class="lock-btn ls-flex-ajc" @click="ToLock()">To Lock</div>
					<div class="lock-btn ls-flex-ajc" @click="ToUnlock()">To Unlock</div>
				</div>
				<div class="col-sm-10" v-if="!nftList.length">
					{{store.state.lang.myDaosNo}}
				</div>
				<div v-if="!nftList.length" class="col-sm-2 ls-flex-ac" @click="toPage('nft')">
					<div class="el-btn ls-btn ls-flex-ajc ls-flex-1">{{store.state.lang.mint}}</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-3 col-12 mb-3" v-for="(item,index) in nftList"
					:key="item.tokenId">
					<div class="nft-info-box">
						<img v-if="item.islock" class="nft-logo lock-nft"
							:src="require('../../assets/img/icon/icon_lock_red.png')" alt="">
						<el-image class="w-100 nft-img" :src="item.pic" :preview-src-list="[item.pic]" fit="cover"
							:hide-on-click-modal="true" />
						<div class="nft-info ls-flex-ac">
							<img class="nft-logo mr-2" :src="require('../../assets/img/icon/logo_w.png')" alt="">
							<div class="nft-info-name">{{item.name}}</div>
						</div>
						<div class="nft-info ls-flex-ajb" style="padding-left: 5px;">
							<div class="nft-info-name">{{item.tokenId}}</div>
							<img class="nft-logo" style="cursor:pointer;" @click="toPage('/invite/'+item.tokenId)"
								:src="require('../../assets/img/icon/icon_share.png')" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-5" style="margin:0;" v-if="!nftList.length">
				<div class="col-md-6 col-sm-12 col-12 profile-node-bar mb-5 ls-flex-ac">{{store.state.lang.myNodes}}
				</div>
				<div class="col-sm-10 c-fff">
					{{store.state.lang.myNodesNo}}
				</div>
				<div class="col-sm-2 ls-flex-ac" @click="toPage('nft')">
					<div class="el-btn ls-btn ls-flex-ajc ls-flex-1">{{store.state.lang.mint}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="page-bg" v-if="lockModalState">
		<div class="page-title">
			LOCK NFT
			<img @click="lockModal()" :src="require('../../assets/img/icon/icon_c.png')" alt="">
		</div>
		<div class="row page-con">
			<div v-if="!canLockList.length" class="ls-flex-ajc c-fff ls-flex-1" style="font-size: 28px;">There are no
				lockable NFTs</div>
			<div class="col-12 col-sm-6 col-md-4 col-lg-2 mb-3" v-for="(item,index) in canLockList" :key="item.pic">
				<div class="nft-info-box" style="cursor: pointer;" @click="selectLockImg(index)">
					<el-image class="w-100 nft-img" :style="item.islock?'border-color: #fff;':''" :src="item.pic" />
					<div class="ls-flex-ajb">
						<div class="nft-info">
							<div class="nft-info-name">{{item.name}}</div>
							<div class="nft-info-name">{{item.tokenId}}</div>
						</div>
						<img v-if="item.islock" class="nft-logo" style="width:30px;"
							:src="require('../../assets/img/icon/icon_check.png')" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="page-footer ls-flex-ajc">
			<div class="btn ls-flex-ajc" @click="lockNft()">LOCK</div>
		</div>
	</div>

	<div class="page-bg" v-if="unlockModalState">
		<div class="page-title">
			UNLOCK NFT
			<img @click="unlockModal()" :src="require('../../assets/img/icon/icon_c.png')" alt="">
		</div>
		<div class="row page-con">
			<div v-if="!canUnlockList.length" class="ls-flex-ajc c-fff ls-flex-1" style="font-size: 28px;">There are no
				unlocked NFTs</div>
			<div v-else class="col-12 col-sm-6 col-md-4 col-lg-2 mb-3" v-for="(item,index) in canUnlockList">
				<div class="nft-info-box" style="cursor: pointer;" @click="selectUnlockImg(index)">
					<el-image class="w-100 nft-img" :style="item.islock?'':'border-color: #fff;'" :src="item.pic" />
					<div class="ls-flex-ajb">
						<div class="nft-info">
							<div class="nft-info-name">{{item.name}}</div>
							<div class="nft-info-name">{{item.tokenId}}</div>
						</div>
						<img class="nft-logo" style="width:30px;"
							:src="item.islock?require('../../assets/img/icon/icon_lock.png'):require('../../assets/img/icon/icon_check.png')"
							alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="page-footer ls-flex-ajc">
			<div class="btn ls-flex-ajc" @click="unLockNft()">UNLOCK</div>
		</div>
	</div>
	
	<comModal :showState="showModalState" :State="stateNum" :StateInfo="StateInfo" :tHash="tHash"></comModal>

</template>

<script setup>
	import {ref,onMounted,watch} from 'vue';
	import {useStore} from 'vuex';
	import {nftAddr,airdropAddr} from '@/utils';
	import {bullNftAbi} from '@/utils/bullNftAbiTest.json';
	import {airdropAbi} from '@/utils/airdropAbi.json';
	import Web3 from "web3";
	import {useRouter} from 'vue-router';
	import axios from 'axios';
	import {ElMessage} from 'element-plus';
	import comModal from "@/components/com-modal/com-modal.vue";
	import formate from "@/utils/formate.js";

	const showModalState = ref(false);
	const StateInfo = ref('');
	const stateNum = ref(0);
	const tHash = ref('');

	const store = useStore();
	const router = useRouter();
	const nftList = ref([]);
	const lockModalState = ref(false);
	const unlockModalState = ref(false);
	const canLockList = ref([]);
	const canUnlockList = ref([]);
	const coinBalance = ref(0);
	const coinBalanceFm = ref(0);

	const toPage = path => router.push(path);

	import {provider} from "@/utils/provider.js";
	
	var web3js;
	if(store.state.isNet == 1){
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			web3js = new Web3();
		} else {
			web3js = new Web3(ethereum);
		}
	}
	else{
		web3js = new Web3(provider);
	}
	
	const getNftContract = new web3js.eth.Contract(bullNftAbi, nftAddr);
	const airdripContract = new web3js.eth.Contract(airdropAbi, airdropAddr);
	
	const getMyCoinBalance = ()=>{
		if(store.state.account){
			airdripContract.methods.balances(store.state.account).call().then((res) => {
				coinBalance.value = res;
				coinBalanceFm.value = formate.formatDecimal(web3js.utils.fromWei(res, 'ether'), 4);
			})
		}
	}
	
	const getNft = () => {
		if (store.state.account) {
			getNftContract.methods.balanceOf(store.state.account).call().then((num) => {
				if (num > 0) {
					let arr = num;
					let nftArr = [];
					for (let i = 0; i < arr; i++) {
						let obj = {};
						getNftContract.methods.tokenOfOwnerByIndex(store.state.account, i).call().then((
						tokenId) => {
							obj.tokenId = tokenId;
							getNftContract.methods.isLocked(tokenId).call().then((islock) => {
								obj.islock = islock;
								getNftContract.methods.tokenURI(tokenId).call().then((url) => {
									let tokenUrl = url.replace('ipfs://',
										'https://ipfs.io/ipfs/');
									obj.url = tokenUrl;
									axios.get(tokenUrl).then((resp) => {
										let data = resp.data;
										obj.name = data.name;
										obj.desc = data.description;
										obj.pic = data.image;
			
										nftArr.push(obj);
										if (i == arr - 1) {
											setTimeout(() => {
												let lockArr = [];
												let unLockArr = [];
												nftList.value = nftArr;
												canLockList.value = [];
												canUnlockList.value = [];
												for (var i = 0; i < nftList.value.length; i++) {
													if (!nftList.value[i].islock) {
														lockArr.push(nftList.value[i])
														canLockList.value = JSON.parse(JSON.stringify(lockArr));
													} else {
														unLockArr.push(nftList.value[i]);
														canUnlockList.value = JSON.parse(JSON.stringify(unLockArr));
													}
												}
											}, 100)
			
										}
			
									}).catch((error) => {
										console.log(error);
									})
								})
							})
						})
					}
				}
			})
		}
		
	}

	onMounted(() => {
		getNft();
		getMyCoinBalance();
	})
	
	watch(store.state,(news,olds)=>{
		getNft();
		getMyCoinBalance();
	})
	
	const selectLockImg = (index) => {
		canLockList.value[index].islock = !canLockList.value[index].islock;
	}

	const selectUnlockImg = (index) => {
		canUnlockList.value[index].islock = !canUnlockList.value[index].islock;
	}
	
	const airdropClaim = ()=>{
		
		if(!store.state.account){
			ElMessage('No wallet is connected, please connect the wallet first');
			return false;
		}
		
		if(BigInt(coinBalance.value)>0){
			showModalState.value = true;
			StateInfo.value = 'Claiming...';
			stateNum.value = 1;
			
			airdripContract.methods.claim().send({
					from: store.state.account
				}).then((res) => {
				tHash.value = res.transactionHash;
				stateNum.value = 2;
				lockModalState.value = false;
				getMyCoinBalance();
			}, (err) => {
				StateInfo.value = err.message;
				stateNum.value = 3;
			})
			
		}
		else{
			ElMessage('Balance is not enough!');
		}
		
	}
	
	const lockNft = () => {
		let arr = [];
		canLockList.value.filter((item) => {
			if (item.islock == true) {
				arr.push(item.tokenId);
			}
		})
		
		if(arr.length==0){
			ElMessage('Please select the nft you want to lock');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Locking...';
		stateNum.value = 1;

		getNftContract.methods.lockToken(arr).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;
			getNft();
			lockModalState.value = false;
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	const unLockNft = () => {
		let arr = [];
		canUnlockList.value.filter((item) => {
			if (!item.islock) {
				arr.push(item.tokenId);
			}
		})
		
		if(arr.length==0){
			ElMessage('Please select the nft you want to unlock');
			return false;
		}
		
		showModalState.value = true;
		StateInfo.value = 'Unlocking...';
		stateNum.value = 1;

		getNftContract.methods.unLockToken(arr).send({
			from: store.state.account
		}).then((res) => {
			tHash.value = res.transactionHash;
			stateNum.value = 2;

			getNft();
			unlockModalState.value = false;
		}, (err) => {
			StateInfo.value = err.message;
			stateNum.value = 3;
		})
	}

	const ToLock = () => {
		// canLockList.value = [];
		// for (var i = 0; i < nftList.value.length; i++) {
		// 	if(!nftList.value[i].islock){
		// 		canLockList.value.push(nftList.value[i]);
		// 	};
		// }
		lockModalState.value = true;
	}

	const ToUnlock = () => {
		// canUnlockList.value = [];
		// for (var i = 0; i < nftList.value.length; i++) {
		// 	if(!!nftList.value[i].islock){
		// 		canUnlockList.value.push(nftList.value[i]);
		// 	};
		// }
		unlockModalState.value = true;
	}
	const lockModal = ()=>{
		lockModalState.value = false;
	}
	const unlockModal = ()=>{
		unlockModalState.value = false;
	}
</script>

<style scoped>
	.paper {
		padding: 30px;
	}

	.page-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 999;
		padding: 0 30px 80px;
	}

	.page-bg .page-title {
		position: relative;
		top: 0;
		left: 0;
		height: 80px;
		line-height: 80px;
		font-size: 30px;
		font-weight: 500;
		color: #fff;
		text-align: center;
	}

	.page-bg .page-title img {
		position: absolute;
		right: 30px;
		top: 10px;
		width: 60px;
		cursor: pointer;
	}

	.page-bg .page-con {
		overflow-y: auto;
		height: calc(100% - 80px);
	}

	.page-bg .page-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 80px;
		line-height: 80px;
		font-size: 30px;
		font-weight: 500;
		color: #fff;
		text-align: center;
	}

	.page-bg .page-footer .btn {
		width: 200px;
		height: 40px;
		border-radius: 20px;
		font-size: 26px;
		color: #fff;
		background-color: #620000;
	}

	.profile-dao-bar,
	.profile-node-bar {
		height: 44px;
		margin-bottom: 44px;
		/* padding: 0px 30px; */
		border-radius: 15px 0px 40px;
		font-size: 18px;
		font-weight: bold;
		color: rgb(255, 255, 255);
	}

	.profile-dao-bar {
		background: #620000;
	}

	.profile-node-bar {
		background: #620000;
	}

	.el-btn {
		width: 80px;
		height: 42px;
		border-radius: 20px;

	}

	.lock-btn {
		width: 120px;
		height: 42px;
		margin: 0 10px;
		border-radius: 20px;
		background-color: #620000;
		cursor: pointer;
	}

	.nft-info-box {
		position: relative;
		padding: 15px;
		box-sizing: border-box;
		/* background-image: url('../../assets/img/nft_bg.png'); */
		background-color: #620000;
		/* background-size: cover;
		background-repeat: no-repeat; */
		border-radius: 10px;
	}
	.nft-info-box .lock-nft{
		position: absolute;
		left: 22px;
		top: 25px;
		width: 30px!important;
		z-index: 2;
	}
	.nft-info-box .nft-img {
		border: 8px solid #260000;
	}

	.nft-info .nft-info-title {
		color: #fff;
		font-size: 30px;
	}

	.nft-info .nft-info-title span {
		font-size: 14px;
		font-weight: bold;
	}

	.nft-info .nft-info-name {
		color: #fff;
		font-size: 18px;
		font-weight: bold;
	}

	.nft-info-box .nft-logo {
		width: 35px;
	}

	.nft-info-footer {
		margin: 15px 0;
	}

	.nft-info-footer .set-btn {
		height: 40px;
		border-radius: 20px;
		background: #000000;
		border: 2px solid #FF0000;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
		cursor: pointer;
		padding: 0px 22px;
	}

	.nft-info-footer .set-btn:hover {
		background-color: #620000;
		color: #fff;
		border: 3px solid #620000;
	}

	.nft-info-footer .share-btn {
		width: 44px;
		cursor: pointer;
	}

	.pd-5 {
		padding: 50px;
		box-sizing: border-box;
	}

	@media (max-width:768px) {
		.paper {
			padding: 15px;
		}

		.el-btn {
			margin-top: 30px;
		}

		.pd-5 {
			padding: 25px;
			box-sizing: border-box;
		}
	}
</style>
